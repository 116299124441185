<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.areaCode') }}
            <div class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteEntity"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.areaCode') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="3">
                <b-form-group
                    :label="$t('form.name')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.name').toLowerCase()"
                      rules="required"
                      vid="areaName"
                  >
                    <b-form-input
                        v-model="formData.areaName"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.name').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.code')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.code').toLowerCase()"
                      rules="required"
                      vid="areaCode"
                  >
                    <b-form-input
                        v-model="formData.areaCode"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.code').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.country')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.country').toLowerCase()"
                      rules="required"
                      vid="country"
                  >
                    <v-select
                        v-model="formData.country"
                        label="title"
                        :options="countryOptions"
                        @input="countryChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.county')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.county').toLowerCase()"
                      rules=""
                      vid="country"
                  >
                    <v-select
                        v-model="formData.county"
                        label="title"
                        :clearable="true"
                        :options="countyOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    :label="$t('form.content')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.content').toLowerCase()"
                      rules="required"
                      vid="content"
                  >
                    <b-link
                        @click="$bvModal.show('bv-modal-post-help')"
                    >
                      <small class="text-primary cursor-pointer">* "Speciális jelölések" súgó</small>
                    </b-link>
                    <editor :value="formData.content" @input="contentUpdated"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
      <help-modal/>
    </b-overlay>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            entity-type="App\Models\AreaCode"
            :entity-id="parseInt(this.$route.params.id)"
            :attributes="this.tabsProperties"
            @refresh-tabs-data="refreshTabsData"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormTextarea, BLink
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import helpModal from '@/views/pages/_components/_modal/areaCode/help.vue'
import tabs from '@/views/pages/_components/_tabs/_areaCode/update.vue'
import editor from "@/views/pages/_components/_helper/editor.vue";

export default {
  components: {
    BLink,
    BFormTextarea,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    helpModal,
    vSelect,
    cancelAndGOTOPreviousPage,
    editor,
    tabs,
  },
  data() {
    return {
      role: 'areaCode',
      formData: {
        id: null,
        country: '',
        county: '',
        areaName: '',
        areaCode: '',
        content: '',
      },
      tabsProperties: {
        images: [],
        files: [],
      },
      countryOptions: [],
      countyOptions: [],
      tabsLoading: false,
      loading: false,
      required,
    }
  },
  created() {
    this.initAreaCode()
  },
  methods: {
    initAreaCode(onlyTabs = false) {
      this.$store.dispatch('fetchAreaCode', this.$route.params.id).then(response => {
        this.processAreaCode(response.data.entity, onlyTabs)
      })
    },
    processAreaCode(areaCode, onlyTabs = false) {
      this.tabsLoading = true

      if (!onlyTabs) {
        this.loading = false
        this.$store.dispatch('fetchAreaCode', this.$route.params.id).then(response => {
          const entity = response.data.entity
          this.formData = entity
          this.formData.id = this.$route.params.id
          this.formData.areaName = entity.area_name
          this.formData.areaCode = entity.area_code

          this.$store.dispatch('fetchCountriesForSelect').then(cResponse => {
            this.countryOptions = cResponse

            if (this.countryOptions) {
              Object.keys(this.countryOptions).forEach(key => {
                if (this.countryOptions[key].value === entity.country_id) {
                  this.formData.country = this.countryOptions[key]
                }
              })
            }
          }).finally(() => {
            this.countryChange()
          })
        })
      }

      this.$refs.tabs.refreshSEO()

      this.tabsProperties = {
        images: [],
        files: [],
      }

      if (areaCode.parsed.paragraphs.length) {
        let tmp = []
        Object.keys(areaCode.parsed.paragraphs).forEach(key => {
          tmp = [areaCode.parsed.paragraphs[key]['data']]

          if (areaCode.parsed.paragraphs[key]['type'] === 'image') {
            this.tabsProperties.images = [...this.tabsProperties.images, ...tmp]
          }
        })
      }

      // fileok kezelése
      if (areaCode.parsed.files.length) {
        let tmp = []
        Object.keys(areaCode.parsed.files).forEach(key => {
          tmp = [areaCode.parsed.files[key]['data']]
          this.tabsProperties.files = [...this.tabsProperties.files, ...tmp]
        })
      }

      this.tabsLoading = false
    },
    contentUpdated(value) {
      this.formData.content = value
    },
    countryChange() {
      this.loading = true
      this.$store.dispatch('fetchCountiesForSelect', {country_id: this.formData.country.value}).then(response => {
        this.countyOptions = response
        let found = false

        if (this.countyOptions) {
          Object.keys(this.countyOptions).forEach(key => {
            if (this.countyOptions[key].value === this.formData.county_id) {
              this.formData.county = this.countyOptions[key]
              found = true
            }
          })
        }

        if (!found) {
          this.formData.county = ''
        }
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.country = fd.country.value
          fd.county = fd.county.value

          this.$store.dispatch('updateAreaCode', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.initAreaCode()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    deleteEntity() {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.delete'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
            cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const id = this.$route.params.id
              this.$store.dispatch('deleteAreaCode', id).then(() => {
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'areaCodes'})
                    : this.$router.go(-1)
              })
            }
          })
    },
    refreshTabsData() {
      this.initAreaCode(true)
    }
  }
}
</script>
