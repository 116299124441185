<template>
  <b-tabs content-class="mt-3">
    <seo-tab
        ref="seoTab"
        :entity-id="entityId"
        entity-type="areaCode"
    />
    <images-tab
        @refresh-data="refreshTabData"
        :data="tabsData.images"
        :entity-id="entityId"
        :entity-type="entityType"
    />
    <files-tab
        @refresh-data="refreshTabData"
        :data="tabsData.files"
        :entity-id="entityId"
        :entity-type="entityType"
    />
    <log-tab
      :entity-id="entityId"
      :entity-type="entityType"
    />
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import imagesTab from '@/views/pages/_components/_tab/_shared/_update/images.vue'
import filesTab from '@/views/pages/_components/_tab/_shared/_update/files.vue'
import logTab from '@/views/pages/_components/_tab/_log/_list.vue'
import seoTab from "@/views/pages/_components/_tab/_shared/_update/seo.vue";

export default {
  components: {
    BTabs,
    seoTab,
    imagesTab,
    filesTab,
    logTab,
  },
  props: {
    attributes: [],
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tabsData: [],
    }
  },
  watch: {
    attributes: {
      handler(value) {
        let tmp = {
          images: [],
          files: [],
        }

        if (value.images.length) tmp.images = value.images
        if (value.files.length) tmp.files = value.files

        this.tabsData = tmp
      }
    }
  },
  methods: {
    refreshTabData() {
      this.$emit('refresh-tabs-data')
    },
    refreshSEO() {
      this.$refs.seoTab.initTab()
    }
  }
}
</script>

<style scoped>

</style>
