<template>
  <b-tab>
    <template #title>
      <feather-icon
          icon="ImageIcon"
          size="21"
      />
      <strong>
        {{ $t('tab.title.images') }}
        &nbsp
        <b-badge v-if="dangerCnt > 0" variant="danger">{{ dangerCnt }}</b-badge>
      </strong>
    </template>

    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-row>
          <!-- képek renderelése -->
          <b-col
              v-for="(item, index) in tabData"
              :key="item.id"
              sm="2"
          >
            <!-- mentett kép? -->
            <b-card
                v-if="item.saved"
                overlay
                :img-src="item.url"
                :title="item.name"
                img-top
            >
              <b-card-text>
                <div class="mb-0">
                  <b-button
                      variant="success"
                      size="sm"
                      @click.prevent="replaceFileModal(item.id, item.name, item.file_id)"
                  >
                    <feather-icon
                        icon="RefreshCwIcon"
                        size="15"
                    />
                  </b-button>
                  <b-button
                      variant="danger"
                      size="sm"
                      class="ml-1"
                      @click.prevent="deleteFile(item.file_id)"
                  >
                    <feather-icon
                        icon="TrashIcon"
                        size="15"
                    />
                  </b-button>
                  <b-link
                      :href="item.download_url"
                      target="_blank"
                  >
                    <b-button
                        variant="primary"
                        size="sm"
                        class="ml-1"
                    >
                      <feather-icon
                          icon="DownloadIcon"
                          size="15"
                      />
                    </b-button>
                  </b-link>
                </div>
              </b-card-text>
            </b-card>
            <b-card
                v-else
                overlay
                :img-src="require('@/assets/images/fallback-image-200-200.png')"
                :title="item.name"
                img-top
                class="position-static"
                @click.prevent="uploadFileModal(item.name, item.subtitle)"
            >
              <b-button
                  variant="success"
                  size="sm"
                  class="float-left"
                  @click.prevent="uploadFileModal(item.name)"
              >
                <feather-icon
                    icon="ShareIcon"
                    size="15"
                />
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>

    <!--  add file modal  -->
    <b-modal
        id="add-file-modal"
        size="sm"
        :title="this.tmp.name"
        hide-footer
    >
      <validation-observer ref="imageUploadValidationObserver">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group
                  :label="$t('form.image')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.image').toLowerCase()"
                    rules="required"
                    vid="title"
                >
                  <b-form-file
                      v-model="tmp.image"
                      :state="Boolean(tmp.image)"
                      :placeholder="$t('label.fileInputPlaceholder')"
                      :drop-placeholder="$t('label.fileInputDropPlaceholder')"
                      :browse-text="$t('label.browse')"
                      accept="
                      image/bmp,
                      image/gif,
                      image/jpeg,
                      image/png,
                      image/webp
                      "
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--   save   -->
      <b-button variant="success" class="mt-1 float-right" @click="sendImage">
        {{ $t('label.button.save') }}
      </b-button>
      <!--   close   -->
      <b-button variant="danger" class="mt-1 float-left" @click="closeModal('add-file-modal')">
        {{ $t('label.button.close') }}
      </b-button>
    </b-modal>

    <!--  replace file modal  -->
    <b-modal
        id="replace-file-modal"
        size="sm"
        :title="$t('modal.title.replaceImage')+' (' + this.tmp.name + ')'"
        hide-footer
    >
      <validation-observer ref="imageReplaceValidationObserver">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group
                  :label="$t('form.image')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.image').toLowerCase()"
                    rules="required"
                    vid="title"
                >
                  <b-form-file
                      v-model="tmp.image"
                      :state="Boolean(tmp.image)"
                      :placeholder="$t('label.fileInputPlaceholder')"
                      :drop-placeholder="$t('label.fileInputDropPlaceholder')"
                      :browse-text="$t('label.browse')"
                      accept="
                      image/bmp,
                      image/gif,
                      image/jpeg,
                      image/png,
                      image/webp
                      "
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--   close   -->
      <b-button variant="danger" class="mt-1 float-left" @click="closeModal('replace-file-modal')">
        {{ $t('label.button.close') }}
      </b-button>
      <!--   save   -->
      <b-button variant="success" class="mt-1 float-right" @click="replaceImage">
        {{ $t('label.button.save') }}
      </b-button>
    </b-modal>
  </b-tab>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BButton,
  BFormFile,
  BOverlay,
  BModal,
  BForm,
  BFormGroup,
  BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BButton,
    BFormFile,
    BOverlay,
    BModal,
    BForm,
    BFormGroup,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    data: []
  },
  data() {
    return {
      loading: false,
      tabData: [],
      dangerCnt: 0,
      images: [],
      required,
      tmp: {
        id: null,
        name: null,
        alt: '',
        image: null,
      },
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
        this.initTab()
      }
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    initTab() {
      this.dangerCnt = 0
      if (this.tabData.length) {
        Object.keys(this.tabData).forEach(key => {
          if (!this.tabData[key]['saved']) {
            this.dangerCnt += 1
          }
        })
      }
    },
    uploadFileModal(imageName, alt) {
      this.tmp.name = imageName
      this.tmp.alt = alt
      this.$bvModal.show('add-file-modal')
    },
    sendImage() {
      this.$refs.imageUploadValidationObserver.validate().then(success => {
        if (success) {
          this.showLoadBar()
          const fd = new FormData()
          fd.append('customFileName', this.tmp.name)
          fd.append('modelType', this.entityType)
          fd.append('modelId', this.entityId)
          fd.append('alt', this.tmp.alt)
          fd.append('file', this.tmp.image)

          this.$store.dispatch('storeImage', fd).then(() => {
            this.$bvModal.hide('add-file-modal')
            this.resetTempData()
            this.$emit('refresh-data')
            this.$helpers.showSuccessToast()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$helpers.showErrorToast(error.response.data.errors[0])
            } else if (error.response.status === 413) {
              this.$helpers.showErrorToast(this.$t('text.api.response.tooBigImages'))
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.hideLoadBar()
          })
        }
      })
    },
    replaceFileModal(imageId, imageName, fileId) {
      this.tmp.id = imageId
      this.tmp.fileId = fileId
      this.tmp.name = imageName
      this.$bvModal.show('replace-file-modal')
    },
    replaceImage() {
      this.$refs.imageReplaceValidationObserver.validate().then(success => {
        if (success) {
          this.showLoadBar()

          const fd = new FormData()
          fd.append('modelType', this.entityType)
          fd.append('modelId', this.entityId)
          fd.append('fileId', this.tmp.fileId)

          // törlés
          this.$store.dispatch('deleteFile', fd).then(() => {

            const fd = new FormData()
            fd.append('customFileName', this.tmp.name)
            fd.append('modelType', this.entityType)
            fd.append('modelId', this.entityId)
            fd.append('alt', this.tmp.alt)
            fd.append('file', this.tmp.image)

            this.$store.dispatch('storeImage', fd).then(() => {
              this.$bvModal.hide('replace-file-modal')
              this.resetTempData()
              this.$emit('refresh-data')
              this.$helpers.showSuccessToast()
            }).catch(error => {
              if (error.response.status === 422) {
                this.$helpers.showErrorToast(error.response.data.errors[0])
              } else if (error.response.status === 413) {
                this.$helpers.showErrorToast(this.$t('text.api.response.tooBigImages'))
              } else {
                this.$helpers.showErrorToast(error.toString())
              }
            }).finally(() => {
              this.hideLoadBar()
            })

          }).catch(error => {
            this.$helpers.showErrorToast(error.toString())
            this.hideLoadBar()
          })
        }
      })
    },
    deleteFile(fileId, showLoadBar = true) {
      this.$bvModal
          .msgBoxConfirm(this.$t('modal.title.confirmDeleteImage'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('label.yes'),
            cancelTitle: this.$t('label.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const fd = new FormData()
              fd.append('modelType', this.entityType)
              fd.append('modelId', this.entityId)
              fd.append('fileId', fileId)

              if (showLoadBar) this.showLoadBar()
              // axios
              this.$store.dispatch('deleteFile', fd).then(() => {
                this.$emit('refresh-data')
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {fileId}))
              }).finally(() => {
                if (showLoadBar) this.hideLoadBar()
              })
            }
          })
    },
    // külön metódus kell, hogy kiüssük a tem datat is
    closeModal(modalName) {
      this.$bvModal.hide(modalName)
      this.resetTempData()
    },
    resetTempData() {
      this.tmp = {
        id: null,
        name: null,
        alt: '',
        image: null,
      }
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
